import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";

const service = new Service();

export const GetListAll = (
  keyword,
  provinceId,
  districtId,
  status,
  pageIndex,
  pageSize
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  keyword && params.append("keyword", keyword);
  provinceId && params.append("provinceId", provinceId);
  districtId && params.append("districtId", districtId);
  status !== 3 && params.append("status", status);
  return service
    .get(ApiUrl.Posts_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailPosts = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.Posts_GetDetail.replace("{id}", id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreatePosts = (body) => {
  return service
    .post(ApiUrl.Posts_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdatePosts = (body) => {
  return service
    .post(ApiUrl.Posts_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeletePosts = (id, xmin) => {
  //const params = new URLSearchParams();
  //params.append("id",id);
  return service
    .postParams(ApiUrl.Posts_Delete + "/" + id + "/" + xmin)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.Posts_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const RestorePosts = (id) => {
  return service
    .postParams(ApiUrl.Posts_Restore.replace("{id}", id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
