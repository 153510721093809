import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";
import * as configuration from "../../../utils/configuration";
import dateformat from "dateformat";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Dialog, DialogContent } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: "title", hideSortIcon: false, label: "Tiêu đề" },
  { id: "title", hideSortIcon: false, label: "Nội dung" },
  { id: "image_Url", hideSortIcon: true, label: "Trạng thái" },
  { id: "viewCount", hideSortIcon: false, label: "Hình ảnh" },
  { id: "viewCount", hideSortIcon: false, label: "Video" },
  { id: "status", hideSortIcon: false, label: "Quận/Huyện" },
  { id: "isHot", hideSortIcon: false, label: "Địa chỉ" },
  { id: "isHot", hideSortIcon: false, label: "Trả lời" },
  { id: "isHot", hideSortIcon: false, label: "Comment" },
  { id: "createdDate", hideSortIcon: false, label: "Ngày tạo" },
  { id: "createdBy", hideSortIcon: false, label: "Người tạo" },
  { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              "pt-3 pb-3 text-nowrap" +
              (headCell.id === "planningName" ? "MuiTableCell-freeze" : "")
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListPosts(props) {
  const {
    editAction,
    deleteAction,
    restoreAction,
    postsModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListAll,
    title,
    categoryId,
    isDelete,
    order,
    page,
    rowsPerPage,
    orderBy,
  } = props;

  //--- Config table
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [openVideoDialog, setOpenVideoDialog] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');
  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    if (property !== "avatar") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      let sort = isAsc ? "desc" : "asc";
      let sortExpression = property + " " + sort;

      GetListAll(
        categoryId,
        title,
        undefined,
        undefined,
        isDelete,
        undefined,
        page + 1,
        rowsPerPage,
        sortExpression
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let sortExpression = orderBy + " " + order;
    GetListAll(
      categoryId,
      title,
      undefined,
      undefined,
      isDelete,
      undefined,
      newPage + 1,
      rowsPerPage,
      sortExpression
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let sortExpression = orderBy + " " + order;
    console.log(event.target.value);
    GetListAll(
      categoryId,
      title,
      undefined,
      undefined,
      isDelete,
      undefined,
      1,
      event.target.value,
      sortExpression
    );
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, postsModels.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
         {isOpen && (
          <Lightbox
            mainSrc={imageUrl}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={postsModels.length}
            />
            <TableBody>
              {postsModels && postsModels.length > 0 ? (
                postsModels.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell className="shadow-sm">{row.title}</TableCell>
                      {row.content.length > 150 ? (
                        <Tooltip title={row.content} arrow>
                          <TableCell className="MuiTableCell-freeze">
                            {row.content.slice(0, 150)}...
                          </TableCell>
                        </Tooltip>
                      ) : (
                        <TableCell className="MuiTableCell-freeze">
                          {row.content}
                        </TableCell>
                      )}

                      <TableCell className="text-center">
                        {row.statusActionName}
                      </TableCell>
                      <TableCell>
                        <img
                          src={configuration.APIUrlDefault + row.image_Url}
                          onClick={()=>{
                              setIsOpen(true)
                              setImageUrl(row.image_Url)
                          }}
                          style={{cursor: "pointer"}}
                          onError={(e) =>
                            (e.target.src =
                              process.env.PUBLIC_URL + "/logo.png")
                          }
                          alt={row.title}
                          className="logo"
                        />
                      </TableCell>
                      <TableCell cassName="text-center" onClick={()=>setOpenVideoDialog(true)}>
                        {row.videoAttemp}
                      </TableCell>
                      <TableCell cassName="text-center">
                        {row.districtName}
                      </TableCell>
                      <TableCell cassName="text-center">
                        {row.address}
                      </TableCell>
                      <TableCell cassName="text-center">
                        {row.comments}
                      </TableCell>
                      <TableCell>{row.created_by}</TableCell>
                      <TableCell>
                        {dateformat(row.created_date, "dd/mm/yyyy")}
                      </TableCell>

                      <TableCell align="right" className="text-nowrap">
                        <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editAction(row.id)}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Xóa">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteAction(row.id, row.xmin)}
                          >
                            <DeleteIcon className="text-danger" />
                          </IconButton>
                        </Tooltip>
                        {row.isDelete ? (
                          <Tooltip title="Khôi phục">
                            <IconButton
                              aria-label="restore"
                              onClick={() => restoreAction(row.id)}
                            >
                              <RestoreIcon className="text-success" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={8}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {openVideoDialog && (
        <Dialog
          open={openVideoDialog}
          onClose={() => setOpenVideoDialog(false)}
          aria-labelledby="annoucedDialog"
          aria-describedby="annoucedDescription"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogContent className="p-0">
            <div className="w-100">
            aaaaaaaaaaaaaaaaaa
            </div>
          </DialogContent>
        </Dialog>
      )}
        {totalItemCount && totalItemCount > 0 ? (
          <TablePagination
            rowsPerPageOptions={Configs.DefaultPageSizeOption}
            component="div"
            count={totalItemCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Số hàng mỗi trang"}
            labelDisplayedRows={({ from, to, count }) => {
              return "" + from + "-" + to + " trong " + count;
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
}
